import { Reference } from '@apollo/client';
import { Page } from '../apollo/apollo-cache.ts';

export const updateFeedCache = (
  feedPage: Page,
  newItemRef: Reference | undefined,
): Page => {
  if (newItemRef) {
    const idx = feedPage.items.findIndex(
      (item) => (item as { __ref: string }).__ref === newItemRef.__ref,
    );

    const items =
      idx === -1
        ? [newItemRef, ...feedPage.items]
        : feedPage.items
            .slice(0, idx)
            .concat(newItemRef)
            .concat(feedPage.items.slice(idx + 1));

    return {
      ...feedPage,
      items,
    };
  }

  return feedPage;
};
