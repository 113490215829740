import { Button } from '@synoptic/ui-kit/button/button.js';
import { IconButton } from '@synoptic/ui-kit/button/icon-button.tsx';
import { ArrowLeftIcon } from '@synoptic/ui-kit/icons/react/arrow-left.tsx';
import { Input } from '@synoptic/ui-kit/input/input.tsx';
import { modalHeader } from '@synoptic/ui-kit/modal/modal.css.ts';
import { ModalTitle } from '@synoptic/ui-kit/modal/modal.tsx';
import React, { useRef, useState } from 'react';
import { newPostForm } from './form.css';
import {
  newPostModalHeaderRevealTitle,
  newPostRevealActionsButtons,
  newPostRevealActionSetTimeButton,
  newPostRevealDescription,
  newPostRevealInput,
  newPostRevealContainer,
  newPostRevealInnerInput,
} from './set-reveal-time-form.css';

export type RevealTimeInputs = {
  hours: string;
  minutes: string;
  seconds: string;
};

export type SetRevealTimeFormProps = {
  values: RevealTimeInputs;
  onSubmit: (input: RevealTimeInputs) => void;
  onCancel: () => void;
};

const onlyNumbersRegExp = /^[0-9\b]+$/;

export const SetRevealTimeForm: React.FC<SetRevealTimeFormProps> = ({
  values,
  onSubmit,
  onCancel,
}) => {
  const [tmpRevealTime, setTmpRevealTime] = useState<RevealTimeInputs>(values);
  const [revealTimeErrors, setRevealTimeErrors] = useState({
    hours: false,
    minutes: false,
    seconds: false,
  });

  const formRef = useRef<HTMLFormElement>(null);

  const handleRevealInputTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    timeType: 'hours' | 'minutes' | 'seconds',
  ) => {
    const { value } = event.target;
    const inputMaxLength = timeType === 'hours' ? 3 : 2;
    const inputMaxValue = timeType === 'hours' ? 720 : 59;
    if (
      (value === '' || onlyNumbersRegExp.test(value)) &&
      value.length <= inputMaxLength &&
      !(value.length > 1 && value.startsWith('0'))
    ) {
      setRevealTimeErrors((prevState) => ({
        ...prevState,
        [timeType]: false,
      }));
      setTmpRevealTime((prevState) => {
        if (Number(value) > inputMaxValue) {
          setRevealTimeErrors((prevState) => ({
            ...prevState,
            [timeType]: true,
          }));
        }
        return {
          ...prevState,
          [timeType]: value,
        };
      });
    }
  };

  const submit = () => {
    onSubmit(tmpRevealTime);
  };

  return (
    <form
      className={newPostForm}
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
      onKeyDownCapture={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <div className={modalHeader}>
        <IconButton onClick={onCancel} variant="ghost" size="small">
          <ArrowLeftIcon style={{ height: 24, width: 24 }} />
        </IconButton>
        <ModalTitle className={newPostModalHeaderRevealTitle}>
          Set reveal time
        </ModalTitle>
      </div>

      <p className={newPostRevealDescription}>
        Choose when you want your post to be revealed to others. Select a time
        delay, and your post will automatically become visible after the chosen
        duration.
      </p>
      <div className={newPostRevealContainer}>
        <Input
          type="text"
          value={tmpRevealTime.hours}
          autoFocus
          placeholder="Enter hours"
          errorMessage={
            revealTimeErrors.hours ? 'Max value 720 hours' : undefined
          }
          className={newPostRevealInput}
          inputClassName={newPostRevealInnerInput}
          onChange={(event) => {
            handleRevealInputTimeChange(event, 'hours');
          }}
          label={'Hours'}
        />
        <Input
          type="text"
          value={tmpRevealTime.minutes}
          autoFocus
          placeholder="Enter minutes"
          errorMessage={
            revealTimeErrors.minutes ? 'Max value 59 minutes' : undefined
          }
          className={newPostRevealInput}
          inputClassName={newPostRevealInnerInput}
          onChange={(event) => {
            handleRevealInputTimeChange(event, 'minutes');
          }}
          label={'Minutes'}
        />
        <Input
          type="text"
          value={tmpRevealTime.seconds}
          autoFocus
          placeholder="Enter seconds"
          errorMessage={
            revealTimeErrors.seconds ? 'Max value 59 seconds' : undefined
          }
          className={newPostRevealInput}
          inputClassName={newPostRevealInnerInput}
          onChange={(event) => {
            handleRevealInputTimeChange(event, 'seconds');
          }}
          label={'Seconds'}
        />
      </div>

      <div className={newPostRevealActionsButtons}>
        <Button
          type="button"
          size={'medium'}
          onClick={onCancel}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          size={'medium'}
          className={newPostRevealActionSetTimeButton}
          disabled={
            Object.values(tmpRevealTime).every((value) => value === '0') ||
            !Object.values(tmpRevealTime).some((value) => Number(value) > 0) ||
            Object.values(revealTimeErrors).some((value) => Boolean(value))
          }
        >
          Set Reveal Time
        </Button>
      </div>
    </form>
  );
};
