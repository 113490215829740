import { RevealTimeInputs } from '../components/new-post-modal/set-reveal-time-form';

export const calculateReleaseAt = (timeObject: RevealTimeInputs) => {
  const { hours, minutes, seconds } = timeObject;
  const currentTime = new Date();
  const releaseAt = new Date(currentTime);

  releaseAt.setHours(currentTime.getHours() + parseInt(hours, 10));
  releaseAt.setMinutes(currentTime.getMinutes() + parseInt(minutes, 10));
  releaseAt.setSeconds(currentTime.getSeconds() + parseInt(seconds, 10));

  return releaseAt.toISOString();
};
